/**
 * @param biLoggerDefaults object for bi logger default settings
 * @param webBiLogger bi logger Factory (with updateDefaults, logger .etc methods)
 */
export const getBiLogger = (biLoggerDefaults, webBiLogger) => {
  const biLogger = webBiLogger
    .updateDefaults({
      sessionId: 'exampleStr',
      ...biLoggerDefaults,
    })
    .logger();
  return biLogger;
};
